@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin exercise-searcher-item-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-exercise-searcher-item {
        .web-exercise {
            &__name {
                color: map.get($foreground, text);
            }
        }
    }
}

@mixin exercise-searcher-item-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include exercise-searcher-item-color($theme);
    }
}