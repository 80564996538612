body {
    background-color: var(--background-color);
    color: white;
}

// Elimina los botones de incremento en input tipo number para: Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Elimina los botones de incremento en input tipo number para: Firefox
input[type=number] {
    -moz-appearance: textfield;
}