@use 'sass:map';
@use '@angular/material' as mat;

//---------------------------------------------------------------------
// Panel
//---------------------------------------------------------------------

@mixin sp-panel-color($theme, $background, $foreground) {
    .sp-panel {
        color: map.get($foreground, text);

        &__container {
            background-color: map.get($background, panel);
        }

        &__header {
            &--bordered {
                border: 1px solid map.get($foreground, border);
            }
        }
    }

}

@mixin sp-panel-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-panel-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// sp-buttons
//---------------------------------------------------------------------

@mixin sp-buttons-color($theme, $background, $foreground) {
    .sp-clear-filters-button {
        fill: map.get($background, icon);
    }

}

@mixin sp-buttons-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-buttons-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Table
//---------------------------------------------------------------------

@mixin sp-table-color($theme, $background, $foreground) {
    .sp-table {
        &__th {
            background-color: map.get($background, table-header);
        }
    }

    .mat-table {
        background-color: transparent;
    }

    .mat-paginator {
        background-color: transparent;
    }
}

@mixin sp-table-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-table-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Input search
//---------------------------------------------------------------------

@mixin sp-input-search-color($theme, $background, $foreground) {
    .sp-input-search {
        &__container {
            background-color: map.get($background, background);
            //border-color: map.get($background, divider);
        }

        &__input {
            color: map.get($foreground, text);
        }
    }
}

@mixin sp-input-search-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-input-search-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Editable
//---------------------------------------------------------------------

@mixin sp-editable-color($theme, $background, $foreground) {}

@mixin sp-editable-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-editable-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Notification
//---------------------------------------------------------------------

@mixin sp-notification-color($theme, $background, $foreground) {}

@mixin sp-notification-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-notification-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Card
//---------------------------------------------------------------------

@mixin sp-card-color($theme, $background, $foreground) {
    .sp-card {
        background-color: map.get($background, card);
        color: map.get($foreground, text);
    }
}

@mixin sp-card-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-card-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Upload image
//---------------------------------------------------------------------

@mixin sp-upload-image-color($theme, $background, $foreground) {
    .sp-upload-image {
        &__preview {
            border: 2px dashed map.get($background, divider);
            background-color: map.get($background, background-accent);
        }
    }
}

@mixin sp-upload-image-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include sp-upload-image-color($theme, $background, $foreground);
    }
}

//---------------------------------------------------------------------
// Theme
//---------------------------------------------------------------------

@mixin theme($theme, $background, $foreground) {
    @include sp-editable-theme($theme, $background, $foreground);
    @include sp-table-theme($theme, $background, $foreground);
    @include sp-notification-theme($theme, $background, $foreground);
    @include sp-card-theme($theme, $background, $foreground);
    @include sp-panel-theme($theme, $background, $foreground);
    @include sp-input-search-theme($theme, $background, $foreground);
    @include sp-buttons-theme($theme, $background, $foreground);
    @include sp-upload-image-theme($theme, $background, $foreground);
}