.web-selection-list {
    border-radius: var(--border-radius);

    &__select-all {
        padding: 8px 16px 2px;

        .mat-checkbox {
            label {
                display: flex;

                .mat-checkbox-label {
                    flex-grow: 1;
                }
            }
        }
    }
}