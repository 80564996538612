.web-athletes {

    &__status {
        &--available {
            $color: #03DAC6;
            background-color: rgba($color, 0.1);
            color: $color;
        }

        &--modified {
            $color: #BB86FC;
            background-color: rgba($color, 0.1);
            color: $color;
        }

        &--injured {
            $color: #B00020;
            background-color: rgba($color, 0.1);
            color: lighten($color, $amount: 20);
            
        }

        &--sick {
            $color: #CF6679;
            background-color: rgba($color, 0.1);
            color: $color;
        }

        &--away {
            $color: #3700B3;
            background-color: rgba($color, 0.2);
            color: lighten($color, $amount: 40);
        }
    }

    &__status-indicator {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
    }
}