//$color1: #ea1c2c;
$color1: #FF2D55;
$color2: #10181f;
$color3: #192d3f;
$color4: #a8abb9;
$color5: #6e788f;
$color6: #ecedf4;
$color7: #F8F9FC;

$white: #ffffff;

$accent: $color1;
$primary: $color2;

$primary-web: $color3;
$accent-web: $accent;

$button-height: 36px;
$button-background: $color3;
$button-color: $white;
$button-flat-background: $white;
$button-flat-color: $color5;
$button-basic-color: $color5;
$button-margin-left: 10px;

$icon-button-height: 40px;
$icon-color: $color5;

$menu-option-background: $white;
$menu-option-color: $color5;

$border-color: $color6;
$border-radius: 6px;
$border: 1px solid $border-color;

$background-color: #F6F7FB;
$background-color-selected: #F6F7FB;

$font-size: 14px;

// Ancho grosor de fuentes
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$fw-regular: $font-weight-regular;
$fw-medium: $font-weight-medium;
$fw-semibold: $font-weight-semibold;
$fw-bold: $font-weight-bold;

$font-color-primary: $color2;
$font-color-secondary: $color5;
$font-color-inactive: $color4;
$font-color-no-data: $color4;

$input-border-focus: $color5;

$header-height: 80px;
$margin: 1.5rem;

$padding: 25px;
$padding-sm: 15px;
$padding-item: 8px;

$sidebar-z-index: 1080;

$backdrop-color: rgba(60, 72, 88, 0.75);
$backdrop-modal-z-index: 1090;
$backdrop-spinner-z-index: 1100;

// Verificar que al referenciar al archivo variables.scss desde un @import al parecer se hace referencia al del workspace
$primary:var(--primary-web);

$accent:var(--accent-web);

$font-color-primary: $primary;

$backdrop-color: rgba(82, 95, 127, .25);

:root {

    --white: #FFFFFF;

    // Generals
    --accent-web: #{$accent-web};
    --primary-web: #3799FF;

    // Fonts
    --font-color: #{$primary-web};
    --font-color--secondary: var(--white);
    --font-weight-bold: #{$font-weight-bold};

    // Backdrops
    --backdrop-color: #{$backdrop-color};

    // Backgrounds
    --background-color: #{$color3};
    --background-color--dialog: #F6F7FB;
    --background-color--dark: #{$primary-web};

    --header-height: #{$header-height};

    --margin: #{$margin};
    --padding: #{$padding};

    --button-height:#{$button-height};
    --icon-button-height:#{$icon-button-height};
}