@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin calendar-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    $border: solid 0.5px map.get($background, border);

    .cal-week-view {
        border-top: none;

        .cal-day-column {
            border-left: $border;

            .cal-hour-segment {
                &:hover {
                    background-color: map.get($background, hover) !important;
                }
            }
        }

        .cal-time-events {
            border: none;

            .cal-day-columns {
                border-top: $border;
                border-right: $border;
                border-bottom: $border;
            }
        }

        .cal-hour-odd {
            background-color: map.get($background, background);
        }

        .cal-hour:last-child :not(:last-child) .cal-hour-segment {
            border-bottom: $border;
        }

        .cal-ends-within-day {
            .cal-event {
                background: map.get($background, background-accent);
                color: map.get($foreground, text);
            }
        }

        .cal-event {
            border: none;
        }
    }

    .cal-day-columns .cal-day-column .cal-hour:not(:last-child) .cal-hour-segment {
        border-bottom: solid 0.5px map.get($background, border);
    }

    .cal-month-view {
        .cal-day-cell.cal-weekend {
            .cal-day-number {
                color: map.get($foreground, text);
            }
        }

        .cal-day-cell.cal-out-month {
            .cal-day-number {
                color: map.get($foreground, text-disabled-emphasis);
            }
        }

        .cal-header {
            .cal-cell {
                color: map.get($foreground, text-medium-emphasis);
            }
        }

        .cal-cell-top.active {
            background-color: map.get($background, background);
        }

        .cal-cell-row .cal-cell:hover .cal-cell-top {
            background-color: map.get($background, hover);
        }
    }

    @if($is-dark-theme) {
        .cal-week-view {
            background-color: transparent;

            .cal-ends-within-day {
                .cal-event {
                    box-shadow: none;
                }
            }
        }

        .cal-month-view {
            background-color: transparent;

            .cal-day-number {
                color: map.get($foreground, text);
            }
        }
    }
}

@mixin calendar-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include calendar-color($theme);
    }
}