@use 'sass:map';
@use '@angular/material' as mat;

@mixin base-color($theme, $background, $foreground) {

    ::-webkit-scrollbar-thumb {
        background-color: map.get($background, scrollbar);
        border-radius: 8px;
        box-shadow: inset 0 0 0 5px map-get($background, scrollbar-inset);
    }

    ::-webkit-scrollbar-corner {
        background-color: map.get($background, background);
    }

    select {
        background-color: map.get($background, background-accent);
        color: map.get($foreground, text);
    }
}

@mixin base-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include base-color($theme, $background, $foreground);
    }
}