//$color1: #ea1c2c;
$color1: #FF2D55;
$color2: #10181f;
$color3: #192d3f;
$color4: #a8abb9;
$color5: #6e788f;
$color6: #ecedf4;
$color7: #F8F9FC;

$white: #ffffff;

$accent: $color1;
$primary: $color2;
$warn: #f44336;

$primary-web: $color3;
$accent-web: $accent;

$button-height: 36px;
$button-background: $color3;
$button-color: $white;
$button-flat-background: $white;
$button-flat-color: $color5;
$button-basic-color: $color5;
$button-margin-left: 10px;

$icon-button-height: 40px;
$icon-color: $color5;

$menu-option-background: $white;
$menu-option-color: $color5;

$border-color: $color6;
$border-radius: 6px;
$border: 1px solid $border-color;

$marketplace-border-radius: 20px;

$background-color: #F6F7FB;
$background-color-selected: #F6F7FB;

$font-size: 14px;

// Ancho grosor de fuentes
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$fw-regular: $font-weight-regular;
$fw-medium: $font-weight-medium;
$fw-semibold: $font-weight-semibold;
$fw-bold: $font-weight-bold;

$font-color-primary: $color2;
$font-color-secondary: $color5;
$font-color-inactive: $color4;
$font-color-no-data: $color4;

$input-border-focus: $color5;

$header-height: 80px;
$margin: 1.5rem;

$padding: 25px;
$padding-sm: 15px;
$padding-item: 8px;

$sidebar-z-index: 1080;

$backdrop-color: rgba(60, 72, 88, 0.75);
$backdrop-modal-z-index: 900;
$backdrop-spinner-z-index: 1000;

:root {
    --white: #FFFFFF;

    --primary: #{$color2};
    --primary-web: #3C6CF7;

    --accent: #{$accent};
    --accent-web:#{$accent};

    --warn: #{$warn};

    --font-family: 'Poppins', Montserrat, Caros, Roboto, "Helvetica Neue", sans-serif;
    --font-color: #{$primary};
    --font-color-primary: #{$primary};
    --font-color-accent: #{$accent};
    --font-color-warn: #{$warn};
    --font-color-secondary: #{$font-color-secondary};
    --font-color-no-data: #{$color4};
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    --border-color: #{$border-color};
    --border-color-semi: #{$color4};
    --border-color-dark: rgba(255, 255, 255, 0.25);
    --border-color-disabled: rgba(255, 255, 255, 0.15);
    --border-radius: 6px;
    --header-height: #{$header-height};

    --margin: #{$margin};
    --padding: #{$padding};
    --padding-item: 8px;

    --dark-theme-background: #192d3f;
    --dark-theme-background-accent: #14222f;

    --button-basic-color: #{$color5};
    --button-height: 36px;

    --icon-button-height: 40px;

    --mat-icon-color: #{$color5};

    --calendar-cell-height: 35px;

    --z-index-backdrop-spinner: 1100;

    --status-completed: #00B428;
    --status-missed: #AD1619;
    --status-started: #C88A02;
    --status-scheduled: #0561B6;
}