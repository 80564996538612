@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin athletes-selector-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-athletes-selector {
        &__selection {
            background-color: map.get($background, background-accent);
            border: 1px solid map.get($foreground, border);
        }

        &__select-all {
            border-bottom: 1px solid map.get($background, divider);
        }
    }

    .web-athletes-selector-dialog {
        .web-no-data {
            background-color: map.get($background, background-accent);
        }
    }
}

@mixin athletes-selector-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include athletes-selector-color($theme);
    }
}