.web-table {
  font-size: 16px;

  &--compact {
    .web-table__row {
      height: 42px;
    }

    .web-table__header:first-of-type {
      padding-left: 10px;
    }

    .web-table__cell:first-of-type {
      padding-left: 10px;
    }
  }

  &--selectable {
    .web-table__row {
      cursor: pointer;

      &:not(.web-table__row--selected):hover {
        background: rgba(110, 120, 143, 0.2);
      }
    }
  }

  &--full {
    width: 100%;
  }

  &__header {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    color: #192d3f;
  }

  &__footer {
    color: #192d3f;
    font-weight: var(--font-weight-bold);
  }

  &__row {
    &--selectable {
      cursor: pointer;
    }

    &--selected {
      background: lighten(#6993ff, 10);
    }
  }

  .mat-header-cell ~ &__header {
    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  .mat-cell ~ &__cell {
    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }
}
