@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin container-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .sp-container {
        &--bordered {
            border-color: map.get($background, border);
        }
    }

    .web-container {
        &--sm {
            &::-webkit-scrollbar-thumb {
                background-color: map.get($background, scrollbar);
                box-shadow: inset 0 0 0 7px map.get($background, scrollbar-inset);
            }
        }

        &--secondary {
            &::-webkit-scrollbar-thumb {
                background-color: map.get($background, background);
                box-shadow: inset 0 0 0 5px map.get($background, background-accent);
            }

            &::-webkit-scrollbar-corner {
                background-color: map.get($background, --dark-theme-background-accent);
            }
        }

        &--secondary-sm {
            &::-webkit-scrollbar-thumb {
                background-color: map.get($background, background);
                box-shadow: inset 0 0 0 7px map.get($background, background-accent);
            }
        }
    }

    web-athletes-selector {
        &--secondary {
            &::-webkit-scrollbar-thumb {
                background-color: map.get($background, background);
                box-shadow: inset 0 0 0 5px map.get($background, background-accent);
            }

            &::-webkit-scrollbar-corner {
                background-color: map.get($background, --dark-theme-background-accent);
            }
        }
    }
}

@mixin container-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include container-color($theme);
    }
}