@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin entity-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-entity {
        &__status-icon {
            background-color: map.get($background, background);
        }
    }
}

@mixin entity-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include entity-color($theme);
    }
}