// Estilos de aplicación web/ dashboard
@import './styles/main.scss';

/*
 * ====================================================================
 * = table
 * ====================================================================
 */
.mat-header-cell {
  font-size: 14px;
  font-weight: 700 !important;
  color: #000;
}

.opacity {
  opacity: 0.4;
}

.element-detail {
  border-bottom-style: hidden !important;
}

.element-detail div.expanded {
  overflow: hidden;
  border-bottom: 0;
  position: relative;
}

.element-detail div .row.hover:hover {
  background: #fff;
}

.noContent {
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  line-height: 46px;
  width: 325px;
  height: 240px;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  color: rgba(168, 171, 185, 0.5);
}

/*
 * ====================================================================
 * = cdk-drag-preview
 * ====================================================================
 */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.list.cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


/*
 * ====================================================================
 * = tabs
 * ====================================================================
 */
.mat-tab-label {
  min-width: 72px !important;
}
