@use 'sass:map';
@use '@angular/material' as mat;

@mixin icon-color($theme, $background, $foreground) {

    .sp-icon {
        color: map.get($foreground, text);

        &--fill {
            fill: map.get($foreground, primary-icon);

            svg {
                fill: map.get($foreground, primary-icon);
            }
        }

        &--outline {
            path {
                stroke: map.get($foreground, primary-icon);
            }
        }
    }

    .mat-primary,
    .mat-accent,
    .mat-warn {
        .sp-icon {
            color: white;

            &--fill {
                fill: white;

                svg {
                    fill: white;
                }
            }

            &--outline {
                path {
                    stroke: white;
                }
            }
        }
    }
}

@mixin icon-theme($theme, $background, $foreground) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include icon-color($theme, $background, $foreground);
    }
}