@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin upload-image-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-upload-image {
        &__container {
            border: 2px dashed map.get($background, divider);
            background-color: map.get($background, background-accent);
        }
    }

    :root {
        --cropper-outline-color: transparent;
    }

    image-cropper {
        .overlay {
            outline-color: map.get($background, background-accent) !important;
        }
    }
}

@mixin upload-image-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include upload-image-color($theme);
    }
}