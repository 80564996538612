@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin program-menu-left-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    $hover-background-color: rgb(28, 41, 53);

    .web-program-menu-left {
        .actions {
            &:hover {
                background-color: $hover-background-color;
            }
        }
        .web-add-program-button {
            &:hover{
                background-color: map.get($background, hover);
            }
          }
    }

    @if $is-dark-theme {
        .web-program-menu-left {
            .web-phase {
                background-color: map.get($background, background);
                border: none;
            }

            .web-week {
                background-color: map.get($background, background);
                border: none;
            }
        }
    }
}

@mixin program-menu-left-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include program-menu-left-color($theme);
    }
}