@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin text-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .sp-text {
        color: map.get($foreground, text);

        &--primary {
            color: map.get($foreground, text-primary);
        }

        &--accent {
            color: map.get($foreground, text-accent);
        }

        &--warn {
            color: map.get($foreground, text-warn);
        }

        &--secondary {
            color: map.get($foreground, text-medium-emphasis);
        }

        &--disabled {
            color: map.get($foreground, text-disabled-emphasis);
        }

        &--no-data {
            color: map.get($foreground, text-medium-emphasis);
        }
    }
}

@mixin text-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include text-color($theme);
    }
}