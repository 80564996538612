@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin exercise-block-template-set-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-exercise-block-template-selector {
        .web-template {
            &--selected {
                sp-card {
                    &::before {
                        border: 2px solid map.get($foreground, border-selected);
                    }
                }
            }
        }
    }
}

@mixin exercise-block-template-set-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include exercise-block-template-set-color($theme);
    }
}