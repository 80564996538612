@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin wod-type-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-wod-type {

        input {
            background-color: map.get($background, background);
            color: map.get($foreground, text);
        }

        &__minutes-rounds {
            border: 1px solid map.get($background, border);
        }
    }
}

@mixin wod-type-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include wod-type-color($theme);
    }
}