@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin template-sets-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-template-sets {
        .web-exercise-series {
            &::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 0 7px map.get($background, background-accent);
                background-color: map.get($background, background);
            }
        }
    }
}

@mixin template-sets-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include template-sets-color($theme);
    }
}