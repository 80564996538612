@use 'sass:map';
@use '@angular/material' as mat;
@use './spf-theming-variables' as variables;

@mixin theme($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .ag-theme-spf-dark {
        --ag-background-color: #{map.get($background, background-accent)};
        --ag-header-background-color: #{map.get($background, background)};
        --ag-control-panel-background-color: #{map.get($background, background-accent)};
        --ag-border-color: #{map.get($background, border)};
        --ag-header-column-resize-handle-color: #{map.get($background, border)};
        --ag-row-border-color: #{map.get($background, border)};
        --ag-odd-row-background-color: transparent;

        --ag-font-family: var(--font-family);
    }

    .ag-root-wrapper {
        border: none;
    }

    .ag-header {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }

    .ag-center-cols-viewport {
        // Se visualizaba el scroll original
        overflow: hidden;
    }

    .ag-body-horizontal-scroll {
        ::-webkit-scrollbar-thumb {
            background-color: #{map.get($background, background)};
            box-shadow: inset 0 0 0 7px #{map.get($background, background-accent)};
        }

        ::-webkit-scrollbar-corner {
            background-color: #{map.get($background, background-accent)};
        }
    }
}