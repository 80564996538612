@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin playlist-program-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-playlist-program {
        .phase {
            &__index-name {
                color: map.get($foreground, text-medium-emphasis);
            }
        }

        .week {
            &__name {
                color: map.get($foreground, text-medium-emphasis);
            }
        }
    }

    // Se coloca fuera de .web-playlist-program debido al drag and drop
    .month {
        .day {
            border: 1px solid map.get($background, divider);
        }
    }

    @if $is-dark-theme {
        $hover-background-color: rgb(28, 41, 53);

        .web-playlist-program {
            .phase {
                background-color: map.get($background, background);
                border: none;
            }

            .week {
                background-color: map.get($background, background);
                border: none;
            }

            .actions {
                &:hover {
                    background-color: $hover-background-color;
                }
            }

            .new{
                &:hover{
                    background-color: $hover-background-color;
                }
            }
        }

        // Se coloca fuera de .web-playlist-program debido al drag and drop
        .month {
            .day {
                &--with-workouts {
                    background-color: map.get($background, background) !important;
                    border: none;
                }

                &:hover {
                    background-color: $hover-background-color !important;
                }
            }
        }
    }
}

@mixin playlist-program-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include playlist-program-color($theme);
    }
}