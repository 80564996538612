$color:rgba(#6E788F, .5);

.web-add-option {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    color: $color;

    img,
    mat-icon {
        margin-right: 8px;
    }

    mat-icon {
        width: 16px;
    }

    svg {
        fill: rgb(168, 171, 185);
    }
}

.sp-menu-button {
    color: var(--primary-web);

    mat-icon {
        color: var(--primary-web) !important;
    }
}