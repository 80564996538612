@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin main-widget-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-main-widget {

        &__event {
            background-color: map.get($background, background-accent);
        }

        &__content {
            background-color: transparent;
        }

        &__data-item {
            background-color: map.get($background, background);
        }

        .web-data-item {
            &--empty {
                background-color: transparent;
            }

            &__content:not(:hover) {
                background-color: map.get($background, background-accent);
            }
        }

        sp-clear-filters-button {
            fill: map.get($foreground, icon);
        }
    }
}

@mixin main-widget-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include main-widget-color($theme);
    }
}