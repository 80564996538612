@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin dialog-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    mat-dialog-container {
        background-color: map.get($background, dialog) !important;
    }
}

@mixin dialog-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include dialog-color($theme);
    }
}