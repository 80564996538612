// GridStack
@import '../../../../node_modules/gridstack/dist/src/gridstack.scss';

@import '../../../../node_modules/swiper/swiper-bundle';

@import '../../../../node_modules/@ctrl/ngx-emoji-mart/picker';

// Ag grid
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/*
 * =====================================================
 * = Angular Material
 * =====================================================
 */

.cdk-overlay-dark-backdrop {
  background-color: var(--backdrop-color);
}

.mat-button-toggle {
  font-weight: 500; // TODO: Para igualar el texto de los botones de angular
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 34px !important;
}

/*
 * ====================================================================
 * = Angular calendar
 * ====================================================================
 */

.cal-month-view .cal-days {
  border: none !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border: none !important;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: none !important;
  border-width: 2px;
}

.cal-month-view .cal-days .cal-cell-row,
.cal-month-view .cal-day-cell,
.cal-month-view .cal-cell-top {
  height: var(--calendar-cell-height) !important;
  min-height: var(--calendar-cell-height) !important;
  position: relative;
  outline: none;
}

.cal-month-view .cal-cell-row .cal-cell:hover {
  background-color: transparent !important;
}

.cal-month-view .cal-cell-top.active {
  border-radius: var(--border-radius);
}

.cal-month-view .cal-cell-top.active,
.cal-month-view .cal-cell-row .cal-cell:hover .cal-cell-top {
  cursor: pointer;
}

.cal-month-view .cal-cell-row:hover {
  background-color: transparent !important;
}

.cal-month-view .cal-day-number {
  font-size: 16px !important;
  font-weight: bold !important;
  opacity: 1 !important;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: transparent !important;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number,
.cal-month-view .cal-day-cell.cal-today .event {
  color: var(--primary-web);
}

.cal-month-view .cal-day-badge {
  margin-top: 5px !important;
  margin-right: 5px !important;
  position: absolute;
  right: 0;
  z-index: 2;
  font-size: 10px !important;
}

.cal-month-view .cal-day-number {
  float: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  height: 100%;
}

.cal-week-view .cal-ends-within-day .cal-event {
  box-sizing: border-box !important;
  box-shadow: 0px 6px 8px #f3f3f6;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

mwl-calendar-week-view-header {
  display: none;
}

.cal-time-label-column .cal-hour-segment {
  border: none !important;
}

.cal-hour:first-child>mwl-calendar-week-view-hour-segment .cal-hour-segment {
  display: none;
}

// Para eventos que ocupan menos de 1 línea. Para ver siempre al menos una línea
.cal-week-view .cal-time-events .cal-event-container {
  min-height: 30px;
}

// ------------------------------------------------------
// Estilos para calendario semanal
// ------------------------------------------------------

.cal-week-view .cal-time {
  // position: absolute;
  // margin-top: -16px;
  padding-top: 0 !important;
}

.cal-week-view .cal-time-events .cal-event {
  line-height: 18px !important;
}

// ------------------------------------------------------
// Estilos para calendario mensual
// ------------------------------------------------------

.cal-month-view {
  .cal-days .cal-cell-row {
    margin-bottom: 3px;
  }
}

// ------------------------------------------------------
// Estilos para selector de color
// ------------------------------------------------------

ngx-colors-panel {
  .opened {
    background-color: #192d3f !important;
    // para visualizar 6 elementos
    width: 294px !important;

    .circle {
      border-radius: 0 !important;
    }

    .selected {
      border-radius: 0 !important;
    }
  }
}

/*
 * =====================================================
 * = Joyride step
 * =====================================================
 */

joyride-step {
  .joyride-step {
    &__container {
      font-family: 'Poppins', Montserrat, Caros, Roboto, "Helvetica Neue", sans-serif;
      font-size: 14px;
      border-radius: var(--border-radius);
    }

    &__body {
      max-width: 250px;
    }
  }
}