@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin workout-block-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-workout-block {

        .sp-panel {
            &__container {
                background-color: map.get($background, background);
            }
        }

        .web-add-option {
            border: 1px solid map.get($foreground, border);
            color: rgba(110, 120, 143, 0.5);
        }
    }

    .survey-card-draggable {
      &__content {
        .sp-panel {
          &__container {
            background-color: map.get($background, background);
          }
        }
      }
    }

}

@mixin workout-block-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include workout-block-color($theme);
    }
}
