@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin team-detail-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-team-detail {
        .web-athletes {
            &__selector {
                background-color: map.get($background, background);
            }

            &__select-all {
                border-bottom: 1px solid map.get($background, divider);
            }
        }
    }
}

@mixin team-detail-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include team-detail-color($theme);
    }
}