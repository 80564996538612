@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin event-detail-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-event-detail {
        .web-priorities {
            border: 1px solid map.get($background, border);
        }
    }
}

@mixin event-detail-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include event-detail-color($theme);
    }
}