.web-workout-type {
    &__icon {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        cursor: pointer;

        svg {
            fill: var(--primary-web);
        }

        &--sm {
            width: 1rem !important;
            height: 1rem !important;
            margin-right: 6px;

            .web-workout-type__icon-container {
                height: 1rem;
                width: 1rem;
            }
        }
    }
}