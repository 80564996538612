@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin workout-color($theme) {

    $color-config: mat.get-color-config($theme);
    $is-dark-theme: map.get($color-config, 'is-dark');

    $background: if($is-dark-theme, variables.$dark-theme-background, variables.$light-theme-background);
    $foreground: if($is-dark-theme, variables.$dark-theme-foreground, variables.$light-theme-foreground);

    .web-workout {

        .web-add-option {
            border: 1px solid map.get($foreground, border);
            color: rgba(110, 120, 143, 0.5);
        }

        &__srpe{
            color: map.get($foreground, text);
        }
    }

}

@mixin workout-theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include workout-color($theme);
    }
}