@use 'sass:map';
@use '@angular/material' as mat;
@use '../spf-theming-variables' as variables;

@mixin workout-quick-builder-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, "is-dark");

  $background: if(
    $is-dark-theme,
    variables.$dark-theme-background,
    variables.$light-theme-background
  );
  $foreground: if(
    $is-dark-theme,
    variables.$dark-theme-foreground,
    variables.$light-theme-foreground
  );

  .web-workout-quick-builder {
    .web-no-data {
      background-color: map.get($background, background-accent);
    }

    .web-category {
      &__group {
        background-color: map.get($background, background-accent);
      }
    }

    .web-athletes {
      &__selection {
        background-color: map.get($background, background-accent);
      }

      &__select-all {
        border-bottom: 1px solid map.get($background, divider);
      }
    }

    @if $is-dark-theme {
      .web-athletes {
        &__selection {
          border: none;
        }
      }
    }
  }

  .web-workout-quick-builder__category-group {
    background-color: map.get($background, background-accent);
  }
}

@mixin workout-quick-builder-theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config !=null {
    @include workout-quick-builder-color($theme);
  }
}
